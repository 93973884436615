<template>
    <div>
        <div v-if="isLoading" style="min-height: 300px"
             class="d-flex align-items-center justify-content-center flex-column">
            <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
            <h6 class="text-body mt-3">Loading</h6>

        </div>
        <div v-if="!isLoading && errorLoading" style="min-height: 300px"
             class="d-flex align-items-center justify-content-center flex-column">
            <b-icon-exclamation-triangle class="h3" variant="danger"></b-icon-exclamation-triangle>
            <h6 class="text-danger mt-3">Ooops, there has been an error</h6>

        </div>
        <div v-if="!isLoading && !errorLoading">
            <h5 class="border-bottom pb-2 text-primary">{{ formData.id !== undefined ? "Edit" : "Create"}} additional document</h5>
            <form autocomplete="off">
                <b-form-group
                        label-size="sm"
                        label="Name:">
                    <b-form-input size="sm" v-model="formData.name" placeholder="Additional document name"></b-form-input>
                    <error-display v-model="errors" ident="name"></error-display>
                </b-form-group>

                <b-form-group
                        label-size="sm"
                        label="Document instructions:">
                    <b-form-textarea size="sm" v-model="formData.instructions" placeholder="Instructions shown to user when capturing document"></b-form-textarea>
                    <error-display v-model="errors" ident="instructions"></error-display>
                </b-form-group>

                <b-form-group
                        label-size="sm"
                        label="Show only for the following student status:">
                    <b-select size="sm" v-model="formData.student_status" :options="studentStatus"></b-select>
                    <error-display v-model="errors" ident="student_status"></error-display>
                </b-form-group>

                <b-form-group
                        label-size="sm"
                        label="Only show for these nationality groups:">
                    <div v-if="isLoadingGroups" class="d-flex align-items-center border p-2 rounded">
                        <b-spinner small variant="primary" class="mr-2"></b-spinner>
                        Loading nationality groups
                    </div>
                    <div v-else >
                        <v-select
                                ref="userSelect"
                                label="name"
                                v-model="formData.nationality_group_ids"
                                :multiple="true"
                                :reduce="option => option.value"
                                :options="groups"
                                :filterable="true"
                                placeholder="Search for nationality group">
                        </v-select>
                    </div>
                    <error-display v-model="errors" ident="nationality_group_ids"></error-display>
                </b-form-group>


                <b-form-group>
                    <b-form-checkbox
                            size="sm"
                            v-model="formData.required"
                            value="1"
                            unchecked-value="0"
                    >
                        Required field?
                    </b-form-checkbox>
                    <error-display v-model="errors" ident="required"></error-display>
                </b-form-group>

                <b-form-group>
                    <b-form-checkbox
                            size="sm"
                            v-model="formData.in_export"
                            value="1"
                            unchecked-value="0"
                    >
                        Include in GDPR export?
                    </b-form-checkbox>
                    <error-display v-model="errors" ident="in_export"></error-display>
                </b-form-group>


                <div class="text-right">
                    <b-button @click="saveModel" variant="success">Save</b-button>
                </div>
            </form>

        </div>
    </div>
</template>

<script>
    import {getResource, saveResource} from "../../modules/api/methods";
    import ErrorDisplay from "../form_inputs/ErrorDisplay";
    import _ from 'lodash'
    import {
        additionalDocumentDetail,
        additionalDocumentSave,
        baseApi,
        nationalityGroups
    } from "../../modules/api/endpoints";
    import vSelect from "vue-select"
    import {containsErrors} from "../../modules/helpers/helpers";

    export default {
        name: "AdditionalDocumentEdit",
        components: {"v-select": vSelect, ErrorDisplay},
        props: {
            id: null,
        },
        data: function () {
            return {
                groups : [],
                isLoadingGroups : false,
                errorLoading : false,
                studentStatus : [
                    {value : 'both', text : 'All'},
                    {value : 'students', text : 'Students only'},
                    {value : 'non_students', text : 'Non students'},
                ],
                isLoading: false,
                formData: {},
                errors : {},
                apiEndpoint: baseApi() + 'nationality-groups/dropdown'
            }
        },
        created() {
            this.loadGroups()
            if (this.id != null) {
                this.loadModel();
            }
        },
        methods: {
            loadGroups() {
                this.isLoadingGroups = true;
                getResource(nationalityGroups).then((resp) => {
                    this.groups = resp.data.success
                }).catch((ex) => {
                    this.$root.$children[0].handleApiError(ex, this.loadGroups);
                }).finally(() => {
                    this.isLoadingGroups = false;
                });
            },
            loadModel() {
                this.isLoading = true;
                getResource(additionalDocumentDetail(this.id)).then((resp) => {
                    var data = resp.data.success.data;
                    //TRANSFORM DATA
                    if (data.nationality_groups !== undefined && data.nationality_groups.length > 0) {
                        var ngIds = [];
                        var ngDropdown = [];
                        _.each(data.nationality_groups, function (obj) {
                            ngIds.push(obj.id)
                            ngDropdown.push({value: obj.id, name: obj.name});
                        });
                        data.ng_dropdown = ngDropdown;
                        data.nationality_group_ids = ngIds;
                    }
                    this.formData = data;
                    this.isLoading = false;
                    this.errorLoading = false;
                }).catch((ex) => {
                    this.$root.$children[0].handleApiError(ex,this.loadModel);
                    this.isLoading = false;
                    this.errorLoading = true;
                });
            },
            saveModel() {
                this.isLoading = true;
                this.errors = {};
                saveResource(additionalDocumentSave,this.formData).then((resp) => {
                    this.isLoading = false;
                    this.$router.push({'name' : 'additionalDocuments', params: {data_updated : true}}).catch(error => {});
                }).catch((err) => {
                    if(containsErrors(err)) {
                        this.errors = err.data.errors;
                    } else {
                        this.$root.$children[0].handleApiError(err, this.saveModel);
                    }
                }).finally(() => {
                    this.isLoading = false;
                });
            }
        }
    }
</script>

<style scoped>

</style>
